import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { requestCurrentUser } from 'store/actions/accounts/user';
import { userPropTypes } from 'helpers/proptypes';

import { Loading } from 'components';

export class EventsHomePage extends React.Component {
  static propTypes = {
    userLoading: PropTypes.bool.isRequired,
    userErrored: PropTypes.bool.isRequired,
    user: userPropTypes,
    fetchUserData: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchUserData();
  }

  render() {
    if (this.props.userErrored) {
      return <Redirect to="/events/explore/" />;
    }

    if (this.props.userLoading) {
      return <Loading />;
    }

    if (this.props.user.isAdmin) {
      return <Redirect to="/events/admin-dashboard" />;
    }

    return <Redirect to="/events/explore/" />;
  }
}

const mapStateToProps = ({ accountsUser }) => {
  return {
    userLoading: accountsUser.isLoading,
    userErrored: accountsUser.hasErrored,
    user: accountsUser[accountsUser.current],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserData: () => dispatch(requestCurrentUser()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsHomePage);
